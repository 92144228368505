@font-face {
  font-family: "Avenir";
  src: url("./Avenir-Book.ttf") format("opentype");
}
@font-face {
  font-family: 'Avenir LT Std Black';
  src: local('Avenir LT Std Black'), url('./avenir_ff/AvenirLTStd-Black.otf') format('woff2'),
       url('/avenir_ff/AvenirLTStd-Black.woff') format('woff'),
       url('/avenir_ff/AvenirLTStd-Black.otf') format('opentype');
  font-weight: 900;
  font-style: normal;
}