/* You can add global styles to this file, and also import other style files */
@tailwind base;
@tailwind components;
@tailwind utilities;

// regular style toast
@import 'ngx-toastr/toastr';

@import './assets/styles/index';
@import '@angular/cdk/overlay-prebuilt.css';
body {
  font-family: Avenir;
}

.avenir-bold {
  font-family: 'Avenir LT Std Black';
}

.number-stat-font {
  font-family: Avenir;
  font-size: 36px;
  font-weight: 800;
}

/** overlapping sidebar dropdown fix **/
li > div > .dropdown-menu {
  margin-top: 18px;
}

@keyframes rotate180 {
  to {
    transform: rotateY(0deg);
    transform-origin: center center;
  }
  from {
    transform: rotateY(180deg);
    transform-origin: center center;
  }
}

.animate {
  transition: 0.5s ease-in;
}

.animate-rotate-in {
  transform: rotate(-180deg);
  transform-origin: center center;
}
.animate-rotate-out {
  transform: rotate(-360deg);
  transform-origin: center center;
}

.ng-touched.ng-dirty.ng-invalid + .password-icon__wrapper {
  transform: translate(0px, -11px);
}
.cdk-overlay-pane{
  max-height: max-content;
}
