@use './colors' as colors;

// Buttons
.btn-mango--regular {
  transition:
    background-color 500ms,
    transform 500ms;
  background-color: colors.$color-yl-mango;

  &:focus-visible {
    outline-color: colors.$color-yl-mango;
  }

  &:active {
    transform: scale(0.98);
  }

  &:hover {
    background-color: hsl(37 91% 45% / 1);
  }
}

.btn-mango--disabled {
  background-color: colors.$color-pl-mango;
  pointer-events: none;

  &:focus-visible {
    outline-color: colors.$color-pl-mango;
  }
}

// Inputs
.input--padding {
  padding: 0.625rem 0.875rem;
}

.phone-input--padding {
  padding: 0.625rem 0.875rem 0.625rem 5rem;
}

.phone-input {
  padding: 0.625rem 0.875rem 0.625rem 5rem;
}

.ng-touched.ng-dirty.ng-invalid + .input-icon__wrapper {
  transform: translate(0px, -11px);
}

input:disabled,
input[ng-reflect-is-disabled='true'] {
  pointer-events: none;
  background-color: rgb(249 250 251 / 1);
  color: rgb(107 114 128 / 1);
}

// Form labels
.label--bottom-margin {
  margin-bottom: 0.38rem;
}

// Select inputs
// This works by setting the 'required' attribute on select
// https://stackoverflow.com/a/65356961/7935926
.base__select {
  &:invalid {
    color: #bdbdbd;
  }
}

a:focus-visible {
  outline-color: colors.$color-yl-mango;
}

// Slide in modal
// prevent the user from scrolling outside the modal
body:has(div[role='dialog'][data-open='true']) {
  overflow: hidden;
}

input[type='search']::-webkit-input-placeholder {
  transform: translate(0px, 3px);
}

tbody > tr:last-child {
  border-bottom: 1px solid rgb(209 213 219 / 1);
}
